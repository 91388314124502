<template>
	<v-ons-page class="zutool-data-handling terms">
		<!-- ヘッダー -->
		<common-header
			type="allow"
			@click="onBack()"
		>
			<h1 class="common-header-title__heading">データの取り扱いについて</h1>
		</common-header>
		<!-- /ヘッダー -->
		<section class="terms__container" id="js_dataHandlingContainer">
			<h2 class="terms__title">データの取り扱いについて</h2>

			<article class="terms__box">
				<p>株式会社NTTドコモ（以下、「当社」といいます。）は「頭痛ーる&thinsp;forスゴ得」（以下、「本サービス」といいます。）を通じて、お客さまの利用者情報をご提供いただき、本サービスの円滑な提供を実施させていただくために、以下の方針に基づいて本サービスに登録・入力されたデータを取り扱います。</p>
				<p>本サービスにおいて、当社はお客様個人を特定する情報（個人情報）を取得することはございません。<br>なお、痛み記録入力欄には、個人を特定できるような内容を記載されないようお願いいたします。<br>本サービスに登録されたプロフィール及び痛み記録に関するデータは、お客様本人を特定できない形式で、今後以下の目的で利用いたしますので予めご了承ください。</p>
				<ul class="terms__indent-list">
					<li>&#9312;頭痛の予報精度を向上させて本サービスの改良につなげるため</li>
					<li>&#9313;統計資料、分析結果や有用な情報として公表するため</li>
				</ul>
			</article>
		</section>
	</v-ons-page>
</template>

<script>
// Compornents
import CommonHeader from '../components/Molecules/CommonHeader'

export default {
	name: 'DataHandling',
	components: {
		CommonHeader
	},
	data () {
		return {
		}
	},
	computed: {
	},
	mounted () {
		this.$nextTick(() => {
			const domContainer = document.getElementById('js_dataHandlingContainer')
			if (domContainer) {
				this.$emit('containerHeight', domContainer.clientHeight)
			}
		})
	},
	beforeDestroy () {
	},
	methods: {
		onBack () {
			this.$router.back()
		}
	}
}
</script>

<style lang="scss" scoped>
</style>
